import type { Splide } from '@splidejs/splide';
import { BREAKPOINTS } from '../utils/breakpoints';
import { defineModule, isVisible } from '../utils/helpers';

const splides: Splide[] = [];

const playAll = () =>
  splides.forEach(
    (splide) => isVisible(splide.root) && splide.Components.AutoScroll?.play(),
  );

const pauseAll = () =>
  splides.forEach((splide) => splide.Components.AutoScroll?.pause());

export default defineModule(
  async () => {
    const customersContentBlockElements =
      document.querySelectorAll<HTMLElement>(
        '.content-block.content-block--customers',
      );
    if (!customersContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');
    const { AutoScroll } = await import(
      '@splidejs/splide-extension-auto-scroll'
    );
    const { Intersection } = await import(
      '@splidejs/splide-extension-intersection'
    );

    customersContentBlockElements.forEach((customersContentBlockElement) => {
      const splideElement =
        customersContentBlockElement.querySelector<HTMLElement>('.splide');
      if (!splideElement) return;

      splides.push(
        new Splide(splideElement, {
          type: 'loop',
          autoScroll: {
            autoStart: false,
            speed: 2.5,
            pauseOnHover: false,
            pauseOnFocus: false,
          },
          gap: '3.75rem',
          autoWidth: true,
          pagination: false,
          arrows: false,
          drag: false,
          mediaQuery: 'min',
          intersection: {
            inView: {
              autoScroll: true,
            },
            outView: {
              autoScroll: false,
            },
          },
          breakpoints: {
            [BREAKPOINTS.lg]: {
              gap: '7.625rem',
            },
          },
        }).mount({ AutoScroll, Intersection }),
      );
    });

    window.addEventListener('focus', playAll);
    window.addEventListener('blur', pauseAll);
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);

    window.removeEventListener('focus', playAll);
    window.removeEventListener('blur', pauseAll);
  },
);
