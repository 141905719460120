import type { Splide } from '@splidejs/splide';
import { BREAKPOINTS } from '../utils/breakpoints';
import { defineModule, isVisible } from '../utils/helpers';

const interval = 5000;
const autoplay = 'pause';

const casesBlockSplides: Splide[] = [];

const playAll = () =>
  casesBlockSplides.forEach((splide) => {
    return isVisible(splide.root) && splide.Components.Autoplay?.play();
  });

const pauseAll = () =>
  casesBlockSplides.forEach((splide) => splide.Components.Autoplay?.pause());

export default defineModule(
  async () => {
    const casesContentBlockElements = document.querySelectorAll<HTMLElement>(
      '.content-block.content-block--cases',
    );
    if (!casesContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');
    const { Intersection } = await import(
      '@splidejs/splide-extension-intersection'
    );

    casesContentBlockElements.forEach((casesBlock) => {
      const splideElement = casesBlock.querySelector<HTMLElement>('.splide');
      if (!splideElement) return;

      const splideCompanies = splideElement.querySelectorAll(
        `.splide__slide .author--company`,
      );

      const splide = new Splide(splideElement, {
        arrows: false,
        pagination: true,
        updateOnMove: true,
        mediaQuery: 'min',
        type: 'loop',
        autoplay,
        interval,
        perPage: 1,
        resetProgress: false,
        intersection: {
          rootMargin: '-64px',
          inView: {
            autoplay: true,
          },
          outView: {
            autoplay: false,
          },
        },
        gap: '3rem',
        breakpoints: {
          [BREAKPOINTS.lg]: {},
        },
      });

      const paginationElement = splideElement.querySelector<HTMLUListElement>(
        '.splide__pagination',
      );
      if (paginationElement) {
        // update autoplay interval progress bars
        let progress = '-1';
        splide.on('autoplay:playing', (rate) => {
          const fixedRate = rate.toFixed(2);
          if (progress === fixedRate) return;

          progress = fixedRate;
          paginationElement.style.setProperty(
            '--slide-progress',
            `${progress}`,
          );
        });
      }

      // set the text content of the pagination buttons
      splide.on('pagination:mounted', (data) => {
        data.items.forEach(({ button, page }) => {
          const company = splideCompanies.item(page);
          if (!company) return;

          button.textContent = company.textContent;
        });
      });
      splide.mount({ Intersection });

      casesBlockSplides.push(splide);
    });

    window.addEventListener('focus', playAll);
    window.addEventListener('blur', pauseAll);
  },
  () => {
    if (!casesBlockSplides.length) return;

    while (casesBlockSplides.length) {
      const splide = casesBlockSplides.pop()!;

      // preserve cards
      const { pagination, list } = splide.Components.Elements;
      const tabs = pagination!.querySelectorAll<HTMLLIElement>(
        'li[role="presentation"]',
      );

      list.classList.add('!flex', 'gap-12');

      // const listStyle = list.getAttribute('style') || '';
      splide.destroy(true);
      // copy transforms, but fails big time with clones...
      // list.setAttribute('style', listStyle);

      pagination?.prepend(...tabs);
    }

    window.removeEventListener('focus', playAll);
    window.removeEventListener('blur', pauseAll);
  },
);
