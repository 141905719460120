import type { Splide } from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
        observer.unobserve(entry.target);
      }
    });
  },
  { threshold: 0.3 },
);

const getBlocks = () =>
  document.querySelectorAll<HTMLElement>('.content-block.content-block--team');

const getSplideBlocks = () =>
  document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--team .splide',
  );

export default defineModule(
  async () => {
    const blocks = getBlocks();
    if (!blocks.length) return;
    blocks.forEach((block: HTMLElement) => observer.observe(block));

    const splideBlocks = getSplideBlocks();
    if (!splideBlocks.length) return;

    const { Splide } = await import('@splidejs/splide');

    splideBlocks.forEach((block) =>
      splides.push(
        new Splide(block, {
          autoWidth: true,
          pagination: false,
          gap: '3.375rem',
          mediaQuery: 'min',
          focus: 'center',
          omitEnd: true,
          breakpoints: {
            [BREAKPOINTS.lg]: {
              gap: '3rem',
              focus: 0,
            },
          },
        }).mount(),
      ),
    );
  },
  () => {
    observer.disconnect();

    if (!splides.length) return;
    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
