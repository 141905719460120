import { defineModule } from '../utils/helpers';

const getElements = () => ({
  faqContentBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--faq',
  ),
});

const handleAccordionClick = (
  accordionElement: HTMLElement,
  accordionElements: NodeListOf<HTMLElement>,
) => {
  const isOpen = accordionElement.classList.contains('accordion--open');

  accordionElements.forEach((element) => {
    const isCurrentElement = element === accordionElement;
    const shouldOpen = isCurrentElement && !isOpen;

    element.classList.toggle('accordion--open', shouldOpen);

    const accordionTriggerElement = element.querySelector<HTMLButtonElement>(
      '.accordion__trigger',
    );
    const accordionContentElement = element.querySelector<HTMLButtonElement>(
      '.accordion__content',
    );
    if (!accordionTriggerElement || !accordionContentElement) return;

    accordionTriggerElement.setAttribute(
      'aria-expanded',
      shouldOpen.toString(),
    );
    accordionContentElement.setAttribute(
      'aria-hidden',
      (!shouldOpen).toString(),
    );
  });
};

export default defineModule(
  () => {
    const { faqContentBlockElements } = getElements();

    faqContentBlockElements.forEach((faqContentBlockElement) => {
      const accordionElements =
        faqContentBlockElement.querySelectorAll<HTMLElement>('.accordion');

      accordionElements.forEach((accordionElement) => {
        const accordionTriggerElement =
          accordionElement.querySelector<HTMLButtonElement>(
            '.accordion__trigger',
          );
        if (!accordionTriggerElement) return;

        accordionTriggerElement.addEventListener(
          'click',
          () => handleAccordionClick(accordionElement, accordionElements),
          { passive: true },
        );
      });
    });
  },
  () => {
    const { faqContentBlockElements } = getElements();

    faqContentBlockElements.forEach((faqContentBlockElement) => {
      const accordionElements =
        faqContentBlockElement.querySelectorAll<HTMLElement>('.accordion');

      accordionElements.forEach((accordionElement) => {
        const accordionTriggerElement =
          accordionElement.querySelector<HTMLButtonElement>(
            '.accordion__trigger',
          );
        if (!accordionTriggerElement) return;

        accordionTriggerElement.removeEventListener('click', () =>
          handleAccordionClick(accordionElement, accordionElements),
        );
      });
    });
  },
);
