import { defineModule } from '../utils/helpers';

declare const show_cookie_banner: unknown;
declare const cmplz_set_category_as_body_class: unknown;

export default defineModule(() => {
  // complianz
  if (typeof show_cookie_banner === 'function') show_cookie_banner();
  if (typeof cmplz_set_category_as_body_class == 'function')
    cmplz_set_category_as_body_class();
});
