import { defineModule } from '../utils/helpers';

const scrollToNextSection = (event: MouseEvent) => {
  const button = event.target as HTMLElement;
  button.closest<HTMLElement>('.section')?.nextElementSibling?.scrollIntoView();
};

const getElements = () => ({
  container: document.querySelector('#fullpage'),
  arrows: document.querySelectorAll<HTMLElement>('.fullpage-breaker__arrow'),
});

export default defineModule(
  async () => {
    const { container, arrows } = getElements();
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.body.clientWidth}px`,
    );
    arrows.forEach((arrow) =>
      arrow.addEventListener('click', scrollToNextSection, { passive: true }),
    );
  },
  () => {
    const { arrows } = getElements();
    arrows.forEach((arrow) => {
      arrow.removeEventListener('click', scrollToNextSection);
    });
  },
);
