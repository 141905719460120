import { getNumberOfMatches } from '../api/first2find';
import { defineModule } from '../utils/helpers';

const DURATION = 3000; // duration of counter animation
const UPDATE_RATE = 60; // number of updates per second

let numberOfMatches: number | undefined;

const runCounter = (counter: HTMLElement) => {
  const t0 = performance.now();

  const updateCount = () => {
    if (!numberOfMatches) return;
    const ratio = (performance.now() - t0) / DURATION;
    if (ratio < 1) {
      const count = Math.round(ratio * numberOfMatches);
      counter.innerText = new Intl.NumberFormat('nl-NL').format(count);
      setTimeout(updateCount, 1000 / UPDATE_RATE);
    } else {
      counter.innerText = new Intl.NumberFormat('nl-NL').format(
        numberOfMatches,
      );
    }
  };

  updateCount();
};

const blockObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (!entry.isIntersecting) return;
      blockObserver.unobserve(entry.target);
      entry.target.classList.add('show');
    });
  },
  { threshold: 0.2 },
);

const counterObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (!entry.isIntersecting) return;
      counterObserver.unobserve(entry.target);
      runCounter(entry.target as HTMLElement);
    });
  },
  { threshold: 0.2 },
);

const getBlocks = () => ({
  blocks: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--media-text',
  ),
  counters: document.querySelectorAll<HTMLElement>('.media-text__counter'),
});

export default defineModule(
  async () => {
    const { blocks, counters } = getBlocks();
    blocks.forEach((block) => blockObserver.observe(block));
    try {
      const { matches } = await getNumberOfMatches();
      numberOfMatches = matches;
    } catch (e) {
      //
    }
    if (numberOfMatches === undefined) return;
    counters.forEach((counter) => {
      counter.innerHTML = '0';
    });
    counters.forEach((counter) => counterObserver.observe(counter));
  },
  () => {
    blockObserver.disconnect();
    counterObserver.disconnect();
  },
);
