import { defineModule } from '../utils/helpers';

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
        observer.unobserve(entry.target);
      }
    });
  },
  { threshold: 0.8 },
);

const getBlocks = () =>
  document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--collaborations .collaborations--title, .content-block.content-block--collaborations .collaborations',
  );

export default defineModule(
  () => {
    const blocks = getBlocks();

    blocks.forEach((block: HTMLElement) => observer.observe(block));
  },
  () => {
    observer.disconnect();
  },
);
