import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../utils/overlays';

const { classList: bodyClassList } = document.body;

const getElements = () => ({
  navbarToggleElement:
    document.querySelector<HTMLButtonElement>('.menu__toggle'),
});

const toggleNavbar = () => {
  const { navbarToggleElement } = getElements();

  toggleOverlay(
    Overlay.MENU,
    bodyClassList.toggle('menu--open', !hasOpenOverlay(Overlay.MENU)),
  );

  navbarToggleElement!.ariaExpanded = hasOpenOverlay(Overlay.MENU)
    ? 'true'
    : 'false';
};

const navbarBreakpointChecker = () => {
  const { navbarToggleElement } = getElements();

  toggleOverlay(Overlay.MENU, false);

  navbarToggleElement!.ariaExpanded = hasOpenOverlay(Overlay.MENU)
    ? 'true'
    : 'false';

  bodyClassList.remove('menu--open');
};

export default defineModule(
  () => {
    const { navbarToggleElement } = getElements();
    if (!navbarToggleElement) return;

    navbarToggleElement.addEventListener('click', toggleNavbar);

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
  () => {
    const { navbarToggleElement } = getElements();
    if (!navbarToggleElement) return;

    navbarToggleElement.removeEventListener('click', toggleNavbar);

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );

    toggleOverlay(Overlay.MENU, false);
  },
);
