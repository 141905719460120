import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';

type CacheKey = number | string;

interface CacheItem<T extends unknown> {
  cachedAt: number;
  data: T;
}

export const createCachedAPI = <T>(
  basePath: string,
  {
    cacheTime = 15 * 60 * 1000,
    query = '',
  }: {
    cacheTime?: number;
    query?: string | object;
  } = {},
) => {
  const api = wretch(basePath).addon(QueryStringAddon);

  const cache = new Map<CacheKey, CacheItem<T>>();

  return async (path: string | number = '') => {
    const cachedItem = cache.get(path);

    if (cachedItem && Date.now() - cachedItem.cachedAt <= cacheTime) {
      return cachedItem.data;
    }
    const data = await api.query(query).get(`${path}`).json<T>();
    cache.set(path, { cachedAt: Date.now(), data });

    return data;
  };
};
