export const nextTick = (callback: Function) => {
  setTimeout(callback, 0);
};

export const createElement = (
  tag: keyof HTMLElementTagNameMap,
  options?: Record<string, any> | null,
  children?: HTMLElement[] | string[] | HTMLCollection,
) => {
  const node = Object.assign(document.createElement(tag), options);
  if (children?.length) node.append(...children);
  return node;
};

/**
 * Define a module (useful when doing something with Swup)
 * @param setup Module setup function
 * @param cleanup Module cleanup function
 * @returns Module object
 */
export const defineModule = (
  setup: () => Promise<void> | void,
  cleanup?: () => Promise<void> | void,
) => ({
  setup,
  cleanup,
});

/**
 * Check if the given variable is a function
 * @param fn Variable to check
 * @returns boolean value based on if `fn` is a function
 */
export const isFunction = (fn: unknown): fn is Function =>
  typeof fn === 'function';

/**
 * Checks if an element is visible in the current viewport
 * @param element html element to check
 * @returns boolean visibility
 */
export const isVisible = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    [rect.top, rect.bottom].some(
      (dim) => dim >= 0 && dim <= window.innerHeight,
    ) &&
    [rect.left, rect.right].some((dim) => dim >= 0 && dim <= window.innerWidth)
  );
};
