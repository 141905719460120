import { defineModule } from '../utils/helpers';

const getBlocks = () =>
  document.querySelectorAll<HTMLElement>('.content-block--cost-calculator');

function calculatePrice(
  basePrice: number,
  discountPercentage: number,
  branches: number,
  term: string,
) {
  const totalPricePerMonth = basePrice * branches;

  if (term === 'yearly') {
    return (totalPricePerMonth * 12 * (100 - discountPercentage)) / 100;
  }

  return totalPricePerMonth;
}

const handleBlock = (element: HTMLElement) => {
  const termElement = element.querySelector<HTMLElement>('.price .term');
  const priceElement = element.querySelector<HTMLElement>('.price .amount');

  if (!termElement || !priceElement) return;

  const basePrice = element.querySelector<HTMLInputElement>(
    '[name="base_price"]',
  );
  const discountPercentage = element.querySelector<HTMLInputElement>(
    '[name="discount_percentage"]',
  );
  const branchesInput = element.querySelector<HTMLInputElement>(
    '[name="total_branches"]',
  );
  const termInput = element.querySelector<HTMLSelectElement>('[name="term"]');

  termElement.innerText = `per ${
    termInput?.selectedOptions[0].innerText.toLowerCase() || 'error'
  }`;

  priceElement.innerText = <string>(
    (<unknown>(
      calculatePrice(
        parseInt(basePrice?.value || '0'),
        parseInt(discountPercentage?.value || '0'),
        parseInt(branchesInput?.value || '1'),
        termInput?.value || '',
      )
    ))
  );
};

const handler = (event: Event) => {
  const element = event.currentTarget as HTMLElement;
  handleBlock(element);
};

export default defineModule(
  () => {
    const blocks = getBlocks();
    blocks.forEach((block: HTMLElement) => {
      // Refresh page on Firefox leaves previous values, so this handles those.
      handleBlock(block);

      block.addEventListener('input', handler);
    });
  },
  () => {
    const blocks = getBlocks();
    blocks.forEach((block: HTMLElement) => {
      block.removeEventListener('input', handler);
    });
  },
);
