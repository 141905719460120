import type { WP_REST_API_Post } from 'wp-types';
import { defineModule } from '../../utils/helpers';
import { toggleModal } from '.';
import { getPost } from '../../api/wordpress';

const getElements = () => ({
  collaborationElements: document.querySelectorAll<HTMLElement>(
    '[data-collaboration]',
  ),
});

const setCollaborationModalContent = async (
  collaboration: WP_REST_API_Post,
  imageSrc = '',
) => {
  const modalContentElement = document.querySelector<HTMLElement>(
    '[data-modal="collaboration"] .modal__content',
  );
  if (!modalContentElement) return;

  const collaborationLogoElement =
    modalContentElement.querySelector<HTMLImageElement>('.collaboration__logo');
  const collaborationNameElement =
    modalContentElement.querySelector<HTMLElement>('.collaboration__name');
  const collaborationContentElement =
    modalContentElement.querySelector<HTMLElement>('.collaboration__content');
  if (
    !collaborationLogoElement ||
    !collaborationNameElement ||
    !collaborationContentElement
  )
    return;

  collaborationLogoElement.setAttribute('src', imageSrc);
  collaborationNameElement.innerHTML = collaboration.title.rendered;
  collaborationContentElement.innerHTML = collaboration.content.rendered;

  toggleModal('collaboration', true);
};

const showCollaborationModal = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const collaborationId = e.currentTarget.getAttribute('data-collaboration');
  if (!collaborationId) return;

  const imageSrc = e.currentTarget.querySelector('img')?.src;

  const collaboration = await getPost(`/collaborations/${collaborationId}`);
  if (!collaboration) return;

  return setCollaborationModalContent(collaboration, imageSrc);
};

export default defineModule(() => {
  const { collaborationElements } = getElements();

  collaborationElements.forEach((collaborationElement) => {
    collaborationElement.addEventListener('click', showCollaborationModal);
  });
});
