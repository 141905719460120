import type { Splide } from '@splidejs/splide';
import { defineModule, isVisible } from '../utils/helpers';

const splides: Splide[] = [];

const playAll = () =>
  splides.forEach((splide) => {
    return isVisible(splide.root) && splide.Components.Autoplay?.play();
  });

const pauseAll = () =>
  splides.forEach((splide) => splide.Components.Autoplay?.pause());

export default defineModule(
  async () => {
    const referencesContentBlockElements =
      document.querySelectorAll<HTMLElement>(
        '.content-block.content-block--references',
      );
    if (!referencesContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');
    const { Intersection } = await import(
      '@splidejs/splide-extension-intersection'
    );

    referencesContentBlockElements.forEach((referencesContentBlockElement) => {
      const splideElement =
        referencesContentBlockElement.querySelector<HTMLDivElement>('.splide');
      if (!splideElement) return;

      const slideCount =
        splideElement.querySelectorAll('.splide__slide').length;

      splides.push(
        new Splide(splideElement, {
          arrows: slideCount > 1,
          type: 'loop',
          pagination: false,
          speed: 1000,
          autoplay: 'pause',
          interval: 5000,
          intersection: {
            inView: {
              autoplay: true,
            },
            outView: {
              autoplay: false,
            },
          },
        }).mount({ Intersection }),
      );
    });

    window.addEventListener('focus', playAll);
    window.addEventListener('blur', pauseAll);
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);

    window.removeEventListener('focus', playAll);
    window.removeEventListener('blur', pauseAll);
  },
);
