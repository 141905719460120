import { defineModule } from '../utils/helpers';

const getFormWrapperElement = (form: HTMLFormElement) => {
  const formIdElement = form.querySelector<HTMLInputElement>(
    'input[name="form_id"]',
  );
  if (!formIdElement) return null;

  const formId = formIdElement.value;
  return document.getElementById(`frm_form_${formId}_container`);
};

const initFormMaxPage = () => {
  const formElements = document.querySelectorAll<HTMLElement>('.frm_forms');

  formElements.forEach((formElement) => {
    const maxPageElement = formElement.querySelector<HTMLInputElement>(
      '.frm_rootline_group .frm_page_bar .frm_rootline_single:last-child input',
    );
    if (!maxPageElement) return;

    formElement.style.setProperty(
      '--progress-limit',
      `${maxPageElement.value}`,
    );
    formElement.style.setProperty(
      '--progress-limit-text',
      `'${maxPageElement.value}'`,
    );
  });
};

const onFormComplete = (
  event: Record<string, any>,
  form: HTMLFormElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  response: { errors: string[]; content: string; pass: boolean },
) => {
  const formWrapperElement = getFormWrapperElement(form);
  if (!formWrapperElement) return;

  const modalElement = formWrapperElement.closest('.modal');
  if (modalElement) {
    modalElement.classList.add('modal--form-completed');
  }
};

const onFormPageChange = (
  event: Record<string, any>,
  form: HTMLFormElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  response: { errors: string[]; content: string; pass: boolean; page: number },
) => {
  const formWrapperElement = getFormWrapperElement(form);
  if (!formWrapperElement) return;

  initFormMaxPage();
  formWrapperElement.style.setProperty('--progress-value', `${response.page}`);
  formWrapperElement.style.setProperty(
    '--progress-value-text',
    `'${response.page}'`,
  );
};

export default defineModule(
  () => {
    initFormMaxPage();
    jQuery(document).on('frmFormComplete', onFormComplete);
    jQuery(document).on('frmPageChanged', onFormPageChange);
  },
  () => {
    jQuery(document).off('frmFormComplete', onFormComplete);
    jQuery(document).off('frmPageChanged', onFormPageChange);
  },
);
