import { defineModule } from '../utils/helpers';

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
        observer.unobserve(entry.target);
      }
    });
  },
  { threshold: 0.8 },
);

const getBlocks = () =>
  document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--steps .step',
  );

export default defineModule(
  () => {
    const blocks = getBlocks();
    if (!blocks.length) return;

    blocks.forEach((block: HTMLElement) => observer.observe(block));
  },
  () => {
    const blocks = getBlocks();
    if (!observer) return;

    blocks.forEach((block: HTMLElement) => observer.unobserve(block));
  },
);
