import { toggleModal, type ModalId } from '.';
import { defineModule } from '../../utils/helpers';

export const HASH_TO_MODAL_ID_MAP: Record<string, ModalId> = {
  aanmelden: 'sign-up',
  demo: 'schedule-demo',
};

const onElementClick = (e: Event) => {
  if (!(e.target instanceof HTMLAnchorElement)) return;

  const url = new URL(e.target.href);

  const modalId = HASH_TO_MODAL_ID_MAP[url.hash.substring(1)];
  if (!modalId) return;

  e.preventDefault();

  toggleModal(modalId, true);
};

export default defineModule(
  () => {
    document.addEventListener('click', onElementClick);
  },
  () => {
    document.removeEventListener('click', onElementClick);
  },
);
