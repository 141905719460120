import { defineModule } from '../utils/helpers';

interface AosOptions extends IntersectionObserverInit {
  once: boolean;
  threshold: number;
  selector: string;
  animateClassName: string;
}

const OPTIONS: AosOptions = {
  once: true,
  rootMargin: '-100px 50%',
  threshold: 0,
  selector: '[data-aos]',
  animateClassName: 'aos-animate',
  root: document,
};

const observer = new IntersectionObserver(
  (entries, observer) => {
    const { once, animateClassName } = OPTIONS;

    entries.forEach(({ target, isIntersecting }) => {
      if (!(target instanceof HTMLElement)) return;

      const shouldRepeat =
        target.hasAttribute('data-aos-repeat') ||
        !(target.hasAttribute('data-aos-once') || once);

      if (isIntersecting && !target.classList.contains(animateClassName)) {
        target.classList.add(animateClassName);

        if (!shouldRepeat) {
          observer.unobserve(target);
        }
      } else if (shouldRepeat) {
        target.classList.remove(animateClassName);
      }
    });
  },
  {
    root: OPTIONS.root,
    rootMargin: OPTIONS.rootMargin,
    threshold: OPTIONS.threshold,
  },
);

export default defineModule(
  () => {
    const { selector, animateClassName } = OPTIONS;

    [...document.querySelectorAll<HTMLElement>(selector)]
      .filter((element) => !element.classList.contains(animateClassName))
      .forEach((element) => observer.observe(element));
  },
  () => {
    observer.disconnect();
  },
);
